.mt6 {
  margin-top: 86px;
}

.scrollText {
  width: 100%;
}

.marginsx {
  padding-top: 30px;
}

.spanBg {
  background-image: url(../../public/bgBody.png);
  background-size: cover;
  width: 100%;
  position: absolute;
  min-height: 100%;
  mix-blend-mode: multiply;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  top: 0;
  left: 0;
  z-index: -1;
  border: 1px solid transparent;
}

.btnInputsS:disabled,
.btnInputs:disabled {
  opacity: .8;
  cursor: not-allowed;
}

.mkfButtons {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}

.syncButton {
  cursor: pointer;
}

.fullWidth {
  width: 100%!important;
}

.halfWidth {
  width: 50%;
}

.titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.container {
  padding: 5px 20px;
  /* border: 1px solid red; */
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.titulo h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #0DFF7D;
  margin-bottom: 0px;
}

.handleMsg {
  background-color: #01FE48;
  color: #000D14;
  padding: 10px 15px;
  border-radius: 10px;
  font-weight: 700;
  transition: all .3s;
  border: none;
}

.handleMsg:hover {
  opacity: .8;
}

.abrs {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  /* padding: 5px 15px; */
}

.order {
  max-width: 1000px;
  min-width: 530px;
  flex: 1;
  width: 100%;
  min-height: 416px;
  background: #12262D;
  border-radius: 20px;
  padding: 10px 15px;
  box-shadow: 10px 10px 37px 0px rgba(0, 0, 0, 0.75);
  z-index: 1;
  border: 1px solid #000;
  position: relative;
}

.orderInt {
  display: flex;
  gap: 10px;
}

.left {
  border: 1px solid #E31C1C;
  border-image-slice: 1;
  width: 100%;
  min-height: 362px;
  width: 100%;
  border-radius: 10px;
  overflow: overlay;
}

.right {
  border: 1px solid #01FE48;
  border-image-slice: 1;
  width: 100%;
  min-height: 362px;
  width: 100%;
  border-radius: 10px;
  overflow: overlay;
}

.rightInt {
  width: 100%;
  /* min-height: 362px; */
  width: 100%;
  border-radius: 10px;
  overflow: overlay;
}


.overlays {
  overflow: overlay;
  height: 321px;
  background: #12262D;
}



.left::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.left::-webkit-scrollbar-track {
  border-radius: 4px;
}

.left::-webkit-scrollbar-thumb {
  background: #E31C1C;
  border-radius: 10px;
}

.right::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.right::-webkit-scrollbar-track {
  border-radius: 4px;
}

.right::-webkit-scrollbar-thumb {
  background: #01FE48;
  border-radius: 10px;
}

.body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.body::-webkit-scrollbar-track {
  border-radius: 4px;
}

.body::-webkit-scrollbar-thumb {
  background: #26f2bd;
  border-radius: 10px;
}

.header {
  background: #000D14;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #26F2BD;
  display: flex;
  text-align: center;
  height: 32px;
  align-items: center;
}

.txHeader {
  background: #000D14;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #26F2BD;
  display: flex;
  text-align: center;
  align-items: center;
  min-width: 760px;
  height: 54px;
  border-bottom: 1px solid #26F2BD;
}

.headeer {
  background: #000D14;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #26F2BD;
  display: flex;
  text-align: center;
  height: 32px;
  align-items: center;
}

.buyHeadeers {
  background: #000D14;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #26F2BD;
  display: flex;
  text-align: center;
  align-items: center;
  min-width: 760px;
  border-bottom: 1px solid #26F2BD;
}

.buyHeadeers .col {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: 20px;
  padding: 15px 0;
}

.headeerCabeRed {
  background: #E31C1C;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  display: flex;
  text-align: center;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #E31C1C;
}

.headeerCabeGreen {
  background: linear-gradient(135deg, #0DFF7D 0%, #01FE48 100%);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000D14;
  display: flex;
  text-align: center;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #01FE48;
  /* min-width: 760px; */
}

.body {
  background: #12262D;
  display: flex;
  flex-direction: column;
  height: 267px;
  min-width: 760px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.rowG {
  border-bottom: 1px solid #01FE48;
  border-image-slice: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 2px 6px;
}

.rowR {
  border-bottom: 1px solid #E31C1C;
  border-image-slice: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 2px 6px;
}

.margin0 {
  margin: 0 auto;
}

.data {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 109px;
}

.dol {
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  width: 64px;
}

.askDown {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px;
  line-height: 24px;
  color: #E31C1C;
}

.askUp {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px;
  line-height: 24px;
  background: linear-gradient(135deg, #0DFF7D 0%, #01FE48 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.buyFounder {
  min-height: 411px;
  background: #12262D;
  border-radius: 20px;
  box-shadow: 10px 10px 37px 0px rgba(0, 0, 0, 0.75);
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
  border: 1px solid #000;
  position: relative;
}

.current {
  display: flex;
  gap: 15px;
  align-items: center;
}

.coinX {
  width: 55px !important;
  max-width: 34px;
  /* margin-top:14px; */
}

.coinColumn {
  /* min-width: 100px; */
}

.coinColumn p {
  margin-bottom: 0;
}

.lm-10 {
  margin-left: 10px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.successBadge {
  border-radius: 30px;
  width: 70%;
  padding: 0px 10px;
  margin: 2px 0px;
  background-color: #6CE772;
  color: #12262D !important;
  font-weight: 700 !important;
}

.warningBadge {
  border-radius: 30px;
  width: 70%;
  padding: 0px 10px;
  margin: 2px 0px;
  background-color: #FFC700;
  color: #12262D !important;
  font-weight: 700 !important;
}

.dangerBadge {
  border-radius: 30px;
  width: 70%;
  padding: 0px 10px;
  margin: 2px 0px;
  background-color: #D33C3C;
  font-weight: 700 !important;
}

.orderRow {
  display: flex;
  flex-direction: row;
  text-align: center;
  border-bottom: 1px solid #26F2BD;
  min-width: 760px;
}

.orderRow .col {
  width: 100%;
  border-right: 1px solid #26F2BD;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  /* font-size: 20px; */
  line-height: 26px;
  color: #fff;
  margin-bottom: 0;
}


.mediumText {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 0;
  text-align: center;
}

.bigText {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #fff;
  margin-bottom: 0;
}

#usdt {
  text-align: start;
}


#ftkx {
  text-align: start;
}


.cursorPointer {
  cursor: pointer;
}

.current p {
  font-weight: 400;
  font-size: 14px;
  color: white;
}

.current h4 {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #26F2BD;
  margin-bottom: 0;
}

.current img {
  width: 27px;
  height: 27px;
}

.blocoInputs {
  display: flex;
  gap: 10px;
  min-height: 327px;
}

.inputsLeft,
.inputsRight {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
  flex: 1 1 200px;
}

p.pInput {
  font-weight: 400;
  font-size: 14px;
  color: white;
  margin-bottom: 8px;
  margin-top: 12px;
}

input.inputs[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input.inputs[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.inputRelativ {
  position: relative;
}

.fixInput {
  color: #01FE48;
  font-weight: bold;
  margin-bottom: 0;
  position: absolute;
  top: 0px;
  right: 17px;
  font-size: 12px;
}

.inputs {
  background: #000D14;
  border-radius: 10px;
  padding: 15px 15px;
  color: white;
  border: none;
  width: 100%;
  outline: none;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid transparent;
  position: relative;
}

.inputs:focus {
  border: 1px solid #26F2BD;
}


.inputs:active {
  color: #26F2BD !important;
}


.usdType {
  position: absolute;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  color: #12262D;
  top: 14px;
  right: 10px;
}


.btnInputs {
  width: 55%;
  border-radius: 30px;
  background: #0DFF7D;
  padding: 0px 5px;
  color: #12262D;
  font-weight: 700 !important;
  font-size: 14px;
  border: none;
  margin-top: 5px;
  margin-bottom: 5px;
}

.btnWarningInput {
  width: 55%;
  border-radius: 30px;
  background: #FFC700;
  padding: 0px 5px;
  color: #12262D;
  font-weight: 700 !important;
  font-size: 14px;
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
}

.btnInputs:hover {
  opacity: .8;
}

.warningBtnInputsS {
  position: relative;
  border-radius: 30px;
  background: #FFC700;
  padding: 5px 10px;
  color: #12262D;
  font-weight: 700 !important;
  font-size: 14px;
  border: none;
  margin-top: 5px;
  border: none;
  right: 0;
}

.warningBtnInputsS:hover {
  opacity: .8;
}

.warningBtnInputsS:disabled {
  opacity: .8;
  cursor: not-allowed;
}

.sellBtnInputsS {
  width: 55%;
  border-radius: 30px;
  background: #E31C1C;
  padding: 0px 5px;
  color: #12262D;
  font-weight: 700 !important;
  font-size: 14px;
  border: none;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #FFF;
  border: none;
}

.syncTimeStamp {
  padding: 5px 10px;
  color: #FFF;
  font-weight: 700 !important;
  font-size: 14px;
}

.sellBtnInputsS:hover {
  opacity: .8;
}

.sellBtnInputsS:disabled {
  opacity: .6;
  cursor: not-allowed;
}

.btnInputsS {
  border-radius: 30px;
  background: #E31C1C;
  padding: 5px 10px;
  color: #FFF;
  font-weight: 700 !important;
  font-size: 14px;
  border: none;
  margin-top: 5px;
  border: none;
}

.btnInputsS:hover {
  opacity: .8;
}


.blocoCoin {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  width: 100%;
  margin-top: 30px;
  gap: 10px;
}

p.total {
  font-weight: 700;
  font-size: 12px;
  color: #0DFF7D;
}

.coinFtk {
  font-weight: 700;
  font-size: 11px;
  color: white;
  margin-bottom: 0;
}

.coinFtk span, .coinFtk p {
  color: #26F2BD;
}

.number {
  font-weight: 700;
  font-size: 12px;
  color: white;
}


.transacciones {
  background: linear-gradient(0deg, #26F2BD -36.92%, #12262D 85.32%);
  border-radius: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 257px;
  padding: 11px 13px;
  box-shadow: 10px 10px 37px 0px rgba(0, 0, 0, 0.75);
  z-index: 1;
  border: 1px solid #000;
  position: relative;
  height: 300px;
}

/* .transacciones .table {

} */


.table {
  border-image-slice: 1;
  /* height: 186px; */
  width: 100%;
  border-radius: 10px;
  border: 1px solid #26F2BD;
  margin-bottom: 15px;
  background: #12262D;
}

.bodyOver {
  height: 183px;
  border-radius: 0 0 10px 10px;
  overflow: scroll;
}


.bodyOver::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.bodyOver::-webkit-scrollbar-track {
  border-radius: 4px;
}

.bodyOver::-webkit-scrollbar-thumb {
  background: #01FE48;
  border-radius: 10px;
}

.rons {
  border-image: linear-gradient(3deg, #26F2BD -36.92%, #12262D 85.32%);
  border-bottom: 1px solid;
  border-image-slice: 1;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 10px 6px;
}

.statusGreen,
.statusRed,
.statusYellow,
.statusBlue,
.statusWhite,
.statusOrange {
  padding: 0px 6px;
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
}

.statusGreen {
  background: #0DFF7D;
  color: #12262D;
}

.statusYellow {
  background: #FFC700;
  color: #12262D;
}

.statusRed {
  color: white;
  background: #cd5c5c;
}

.statusBlue {
  background: linear-gradient(48deg, #33C9F0 11.65%, #2C3E85 175.27%);
  color: #12262D;
}

.statusWhite {
  color: #12262D;
  background: #fff;
}

.statusOrange {
  color: #fff;
  background: #b22222;
}


@media screen and (max-width: 1025px) {
  .abrs {
    flex-direction: column;
  }

  .order {
    min-width: 100%;
  }

  .orderInt {
    justify-content: space-around;
  }

  .buyFounder {
    min-width: 100%;
  }

  .inputsLeft,
  .inputsRight {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .table {
    overflow: overlay;
  }

  .table .header {
    min-width: 760px;
  }

  .table .bodyOver {
    font-size: 13px;
    min-width: 760px
  }
}

@media screen and (max-width: 500px) {
  .titulo {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .syncTimeStamp {
    padding: 0;
  }

  .orderInt {
    flex-direction: column !important;
  }

  .rons {
    font-size: 12px;
  }

  .left,
  .right {
    min-width: 100% !important;
    max-width: 100%;
  }

  .pInput {
    margin-bottom: 5px !important;
  }

  .orderInt {
    flex-direction: row;
  }

  .askDown {
    font-size: 12px;
  }

  .askUp {
    font-size: 12px;
  }

  .dol {
    font-size: 12px;
  }

  .blocoInputs {
    flex-direction: column;
    /* min-height: 645px; */
  }

  .inputs,
  .btnInputs,
  .btnInputsS {
    margin-bottom: 15px;
    /* text-align: center !important; */
  }

  .table {
    overflow: overlay;
  }

  .marginsx {
    padding-top: 0px;
  }
}


@media screen and (max-width: 768px) {

  .buyHeadeers .col, .txHeader .col {
    width: 40px;
    font-size: 15px;
    padding: 5px 0;
  }

  .orderRow .col, .bodyOver .col {
    width: 40px;
    font-size: 15px;
    line-height: 26px;
    padding: 5px 0;
  }
  .body, .orderRow, .bodyOver, .buyHeadeers, .txHeader, .table .bodyOver{
    min-width: 520px;
  }
  .rightInt {
    position: relative;
  }
  .body {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
  }
}