@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif !important;
  transition: all .3s ease-in;
}

body {
  height: 100vh;
  width: 100%;
  background-color: rgba(18, 38, 45, 1);
  /* background-image: url(./containers/background.png); */
}
#root{
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow:auto;
}

html{
  background-color: rgba(18, 38, 45, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flexColumn{
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(18, 38, 45, 1);
  height: 100%;
  align-items: center;
  background-image: url(./containers/background.png);
  background-blend-mode: multiply;
  background-size: cover;
  background-repeat: no-repeat;
}



.flexColumnLogin{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.table > :not(caption) > * > * {
  background: transparent;
}

/* .connect-button {
  margin: 22px;
} */


wcm-modal {
  display: none!important;
}