.logoGroup img:nth-child(1) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logoGroup img:nth-last-child(1) {
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.effect{
  background-image: url('../../containers/bgDireitoNew.png');
  background-position: right;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  min-height: 100vh;
}