main {
  background-color: transparent;
  max-width: 768px;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  position: relative;
}

main div img:nth-child(1) {
  width: 100%;
  height: auto;
}

.infoLogin {
  max-width: 352px;
  width: 100%;
  margin: 15px auto;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.infoLogin h1 {
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  color: #fff;
}

.infoLogin p {
  font-size: 17px;
  line-height: 19.2px;
  color: #d9d9d9;
  font-weight: 600;
  line-height: 19.2px;
}

.infoLogin  img {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.infoLogin button:hover {
  opacity: 0.8;
}

.divContent{
  display: flex;
  flex-direction: column;
  justify-content: center;                
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.btnLogin {
  background-color: #08ff65;
  color: #000;
  font-size: 16px;
  padding: 10px;
  border-radius: 12px;
  cursor: pointer;
  border: none;
  font-weight: bold;
}